import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import 'slick-carousel';

//import Headroom from "headroom.js";

/**
 * Anonymous function is autoexecutable.
 */
(function () {

  var $slider = $('.slider');

  if ($slider.length) {

    $slider.each( function() {

      var currentSlide;
      var slidesCount;
      var sliderCounter = $(this).next('.project-counter');

      var updateSliderCounter = function(slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        $(sliderCounter).text(currentSlide + '/' +slidesCount)
      };

      $(this).on('init', function(event, slick) {
        $(this).append(sliderCounter);
        updateSliderCounter(slick);
      });

      $(this).on('afterChange', function(event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
      });

      $(this).slick();

    });

  }

})();